import { useCallback, useState } from 'react'
import { v4 as uuid } from 'uuid'
import { MutationOptions, useMutation, useQueryClient } from 'react-query'

import { DocumentDownloadFormat } from '___types'
import { documentsAPI } from '___api'
import { QUERY_KEYS, useFetchTemplate } from '___queries'
import useFetchDocument from './useFetchDocument'
import { parseAnswersForDownload, parseHeadersFooters } from '.'

export type DownloadDocumentVariables = {
  id: string
  payload: { storageId: string; paragraphs: unknown[] }
  format: DocumentDownloadFormat
  publicFlow?: boolean
}
const downloadDocumentMutationFunction = (variables: DownloadDocumentVariables) =>
  documentsAPI.downloadDocument(variables.id, variables.payload, variables.format, variables.publicFlow)

export type DownloadSignedDocumentVariables = { id: string; split?: string | null; publicFlow?: boolean }
const downloadSignedDocumentMutationFunction = (variables: DownloadSignedDocumentVariables) =>
  documentsAPI.downloadSignedDocument(variables.id, variables.split, variables.publicFlow)

export const useDownloadDocument = (id: string, publicFlow: boolean = false) => {
  const [downloading, setDownloading] = useState<Record<DocumentDownloadFormat | 'signed', string[]>>({ docx: [], pdf: [], signed: [] })
  const { data: document } = useFetchDocument(id, publicFlow)
  const { data: template } = useFetchTemplate(document?.templateId, publicFlow)
  const queryClient = useQueryClient()

  const documentDownloadMutation = useMutation<void, unknown, DownloadDocumentVariables, { mutationId: string }>(
    [QUERY_KEYS.DOCUMENT_DOWNLOAD, id],
    downloadDocumentMutationFunction,
    {
      onMutate: variables => {
        const mutationId = uuid()
        setDownloading(prev => Object.assign(prev, { [variables.format]: prev[variables.format].concat(mutationId) }))
        return { mutationId }
      },
      onSettled: (res, error, variables, context) => {
        setDownloading(prev => Object.assign(prev, { [variables.format]: prev[variables.format].filter(id => id !== context?.mutationId) }))
        queryClient.cancelQueries([QUERY_KEYS.DOCUMENT_DOWNLOAD, id])
        queryClient.removeQueries([QUERY_KEYS.DOCUMENT_DOWNLOAD, id])
      },
    }
  )

  const signedDocumentDownloadMutation = useMutation<void, unknown, DownloadSignedDocumentVariables, { mutationId: string }>(
    [QUERY_KEYS.SIGNED_DOCUMENT_DOWNLOAD, id],
    downloadSignedDocumentMutationFunction,
    {
      onMutate: () => {
        const mutationId = uuid()
        setDownloading(prev => Object.assign(prev, { signed: prev.signed.concat(mutationId) }))
        return { mutationId }
      },
      onSettled: (res, error, variables, context) => {
        setDownloading(prev => Object.assign(prev, { signed: prev.signed.filter(id => id !== context?.mutationId) }))
        queryClient.cancelQueries([QUERY_KEYS.SIGNED_DOCUMENT_DOWNLOAD, id])
        queryClient.removeQueries([QUERY_KEYS.SIGNED_DOCUMENT_DOWNLOAD, id])
      },
    }
  )

  const download = useCallback(
    (
      format: DocumentDownloadFormat,
      splitId?: string | null,
      options?: MutationOptions<void, unknown, DownloadDocumentVariables, { mutationId: string }>
    ) => {
      if (!(document && template)) return
      const { id, answers, languages, externalAPIs } = document
      const { dataStructure, locations } = template
      const payload = {
        storageId: dataStructure.storageId,
        paragraphs: parseAnswersForDownload(template!, languages, externalAPIs, splitId, answers),
      }
      const allLocationParents = Object.keys(Object.assign({}, locations.segments, locations.text))
      const changedHeaders = parseHeadersFooters('headers', dataStructure, allLocationParents)
      const changedFooters = parseHeadersFooters('footers', dataStructure, allLocationParents)
      if (changedHeaders?.length) Object.assign(payload, { headers: changedHeaders })
      if (changedFooters?.length) Object.assign(payload, { footers: changedFooters })
      documentDownloadMutation.mutate({ id, payload, format, publicFlow }, options)
    },
    [document, template, documentDownloadMutation, publicFlow]
  )

  const downloadSigned = useCallback(
    (split?: string | null, options?: MutationOptions<void, unknown, DownloadSignedDocumentVariables, { mutationId: string }>) => {
      if (!document) return
      const { id } = document
      signedDocumentDownloadMutation.mutate({ id, split, publicFlow }, options)
    },
    [document, signedDocumentDownloadMutation, publicFlow]
  )

  return {
    download: document && template ? download : undefined,
    downloadSigned: document ? downloadSigned : undefined,
    downloading: Object.entries(downloading).reduce(
      (result, [format, ids]) => Object.assign(result, { [format]: Boolean(ids.length) }),
      {} as Record<DocumentDownloadFormat | 'signed', boolean>
    ),
  }
}

export type DownloadDocumentFunctionType = (
  format: DocumentDownloadFormat,
  split?: string | null,
  options?: MutationOptions<void, unknown, DownloadDocumentVariables, { mutationId: string }>
) => void
export type DownloadSignedDocumentFunctionType = (
  split?: string | null,
  options?: MutationOptions<void, unknown, DownloadSignedDocumentVariables, { mutationId: string }>
) => void

export default useDownloadDocument
