import { Answers, DataStructure, DOCUMENT_FLOW_MODES, ExternalAPIs, Footer, Header, ITemplate, LanguageValue, Segment } from '___types'
import { rasterizeDataStructure } from 'Layouts/WizardLayout/components/RightPane/RightPane.Editor.Components'

// ====================== IMPLEMENT TYPESCRIPT ====================== //
import { parseV3forBE } from 'Wizard/parsing'
// ================================================================== //

import useApproveDocument from './useApproveDocument'
import useCreateDocument from './useCreateDocument'
import useDownloadDocument from './useDownloadDocument'
import useDuplicateDocument from './useDuplicateDocument'
import useFetchDocument from './useFetchDocument'
import useFetchDocumentFolderList from './useFetchDocumentFolderList'
import useFetchDocumentList, { getDocumentListQueryFunction } from './useFetchDocumentList'
import useGenerateDocumentName from './useGenerateDocumentName'
import usePublishDocument from './usePublishDocument'
import useRemoveDocument from './useRemoveDocument'
import useRequestDocumentSignature from './useRequestDocumentSignature'
import useSubmitDocument from './useSubmitDocument'
import useUpdateDocument from './useUpdateDocument'
import useUploadPDFDocument from './useUploadPDFDocument'

export type { ApproveDocumentVariables, ApproveDocumentFunctionType } from './useApproveDocument'
export type { CreateDocumentVariables, CreateDocumentFunctionType } from './useCreateDocument'
export type { DownloadDocumentVariables, DownloadDocumentFunctionType } from './useDownloadDocument'
export type { DownloadSignedDocumentVariables, DownloadSignedDocumentFunctionType } from './useDownloadDocument'
export type { DuplicateDocumentVariables, DuplicateDocumentFunctionType } from './useDuplicateDocument'
export type { PublishDocumentVariables, PublishDocumentFunctionType } from './usePublishDocument'
export type { RemoveDocumentVariables, RemoveDocumentFunctionType } from './useRemoveDocument'
export type { RequestDocumentSignatureVariables, RequestDocumentSignatureFunctionType } from './useRequestDocumentSignature'
export type { SubmitDocumentVariables, SubmitDocumentFunctionType } from './useSubmitDocument'
export type { UpdateDocumentVariables, UpdateDocumentFunctionType } from './useUpdateDocument'
export type { UploadPDFDocumentVariables, UploadPDFDocumentFunctionType } from './useUploadPDFDocument'

const keyMap = { tableHeader: 'header', tableBody: 'body', tableFooter: 'footer', customStyle: 'styleName' } as Record<string, string>
const keyFilter = ['tag', 'start', 'length']
const keyIgnore = ['id', 'styles']
const parseSegmentsForDownload = (segments: Segment[]) =>
  segments.map(segment =>
    Object.entries(segment).reduce((acc, [key, value]) => {
      if (keyFilter.includes(key)) return acc
      const mapped = (
        keyIgnore.includes(key) ? { [key]: value } : { [keyMap[key] || key]: Array.isArray(value) ? parseSegmentsForDownload(value) : value }
      ) as Record<string, unknown>
      return Object.assign(acc, mapped)
    }, {} as Segment)
  )
export const parseHeadersFooters = <T extends 'headers' | 'footers'>(key: T, dataStructure: DataStructure, locationParentIds: string[]) =>
  dataStructure[key]?.reduce(
    (result, entry) =>
      result.concat(
        locationParentIds.includes(entry.id) || entry.segments?.map(({ id }) => String(id)).some((id: string) => locationParentIds.includes(id))
          ? Object.assign({}, entry, { segments: parseSegmentsForDownload(entry.segments) })
          : []
      ),
    [] as Header[] | Footer[]
  ) as T extends 'headers' ? Header[] : Footer[] | undefined

export const parseAnswersForDownload = (
  template: ITemplate,
  languages?: LanguageValue[],
  externalAPIs?: ExternalAPIs | null,
  split?: string | null,
  answers?: Answers | null
) => {
  const { dataStructure, questions, locations } = template
  const evaluatedDataStructure = rasterizeDataStructure(
    DOCUMENT_FLOW_MODES.PREVIEW,
    dataStructure,
    locations,
    questions,
    languages,
    externalAPIs,
    split,
    answers
  )
  const parsedDataStructure = (parseV3forBE({ dataStructure: evaluatedDataStructure }) as ITemplate).dataStructure
  return parseSegmentsForDownload(parsedDataStructure.segments)
}

export {
  useApproveDocument,
  useCreateDocument,
  useDownloadDocument,
  useDuplicateDocument,
  useFetchDocument,
  useFetchDocumentFolderList,
  useFetchDocumentList,
  useGenerateDocumentName,
  usePublishDocument,
  useRemoveDocument,
  useRequestDocumentSignature,
  useSubmitDocument,
  useUpdateDocument,
  useUploadPDFDocument,
}

export { getDocumentListQueryFunction }
