import {
  ITemplate,
  DataStructure,
  mapTemplatesFilter,
  CASUS_IDS,
  ITemplateFolder,
  TemplatesFilter,
  TEMPLATES_FILTERS,
  PartialTemplate,
  IUser,
} from '___types'
import API from './api'
import { getImageSrcURL, mapStructureKeys, parseCssData, parseStyles, unparseDataStructure, uploadBlobToFirebaseStorage } from './helpers'

export const TEMPLATE_LIST_PER_PAGE = 10

class Templates extends API {
  public constructor() {
    super('templates/')
  }

  public getTemplateList = async (
    id?: string | null,
    lastTemplateId?: string | null,
    requiresPayment?: boolean
  ): Promise<ITemplate[] | undefined> => {
    if (!id) return
    const params = new URLSearchParams({ limit: String(TEMPLATE_LIST_PER_PAGE) })
    if (lastTemplateId) params.set('startAfter', lastTemplateId)
    let res = undefined
    if (Object.values(TEMPLATES_FILTERS).includes(id as TemplatesFilter))
      res = await this.get(`list/${mapTemplatesFilter(id)}?${String(params)}`, undefined, 'v1')
    else res = await this.post(`list/${mapTemplatesFilter(TEMPLATES_FILTERS.MINE)}/category?${String(params)}`, { category: id }, undefined, 'v1') // update path to templates/folders/list (remove the override)
    const templates = (res.data.data.templates as ITemplate[]).map(template => {
      if (!template.isPremium) return template
      return Object.assign(template, { paywallBlocked: requiresPayment })
    })
    return templates
  }

  public getTemplate = async (id?: string | null, publicFlow: boolean = false): Promise<ITemplate | undefined> => {
    if (!id) return
    const res = await this.get(`${publicFlow ? 'public/' : ''}${id}`)
    const template = res.data.data as ITemplate
    const parsedCssData = parseCssData(template.cssData as Record<string, string>)
    const parsedStyles = Object.assign(parsedCssData, { standardStyles: parseStyles(parsedCssData, template.dataStructure) })
    Object.assign(template, { styles: parsedStyles })
    /////////////////////////////////////////////////////////////////////////// //
    ///////////////////////////// V3 ROLLOUT TODO ///////////////////////////// //
    /////////////////////////////////////////////////////////////////////////// //
    // remove parsing when BE fixes styleName => customStyle & id(number) => id(string)
    const parsedDataStructure = Object.assign(mapStructureKeys(template.dataStructure) as DataStructure, { id: CASUS_IDS.DATASTRUCTURE_ID })
    Object.assign(template, { dataStructure: parsedDataStructure })
    // remove parsing when BE migrates (removes) externalAPI field duplicates
    const parsedExternalAPIs = Object.entries(template.externalAPIs || {}).reduce(
      (result, [id, fields]) => Object.assign(result, { [id]: Array.from(new Set(fields)) }),
      {}
    )
    Object.assign(template, { externalAPIs: parsedExternalAPIs })
    // remove parsing when BE migrates (removes) segments locations contentStyle duplicates
    Object.values(template.locations?.segments || {})
      .flat(1)
      .forEach(marker => {
        if (!marker.contentStyles?.length) return
        const arrayOfUniques = Array.from(new Set(marker.contentStyles))
        if (arrayOfUniques.length !== marker.contentStyles.length) Object.assign(marker, { contentStyles: arrayOfUniques })
      })
    //@ts-ignore
    Object.assign(template, { description: template.characteristics }) && delete template.characteristics
    //@ts-ignore
    Object.assign(template, { approvals: template.approvers }) && delete template.approvers
    /////////////////////////////////////////////////////////////////////////// //
    /////////////////////////////////////////////////////////////////////////// //
    /////////////////////////////////////////////////////////////////////////// //

    // const temporary = { id1: 'Split 1 - Employer', id2: 'Split 2 - Employee', id3: 'Split 3 - Other' } // REMOVE AFTER DEVELOPMENT
    // Object.assign(template, { splits: temporary })

    return template
  }

  public createTemplate = async (
    template: PartialTemplate & { base64data: string },
    category: string = TEMPLATES_FILTERS.MINE,
    publicFlow: boolean = false
  ) => {
    const { data: { data: { storageId = '' } = {} } = {} } = await this.post('upload/docx', { base64data: template.base64data })
    const res = await this.post(publicFlow ? 'public' : '', {
      new: true,
      storageId: String(storageId),
      name: template.name,
      category: category ?? '',
    })
    const responseTemplate = res.data.data as ITemplate
    const parsedCssData = parseCssData(responseTemplate.cssData as Record<string, string>)
    const parsedStyles = Object.assign(parsedCssData, { standardStyles: parseStyles(parsedCssData, responseTemplate.dataStructure) })
    Object.assign(responseTemplate, { styles: parsedStyles })
    /////////////////////////////////////////////////////////////////////////// //
    ///////////////////////////// V3 ROLLOUT TODO ///////////////////////////// //
    /////////////////////////////////////////////////////////////////////////// //
    // remove parsing when BE fixes styleName => customStyle & id(number) => id(string)
    const parsedDataStructure = Object.assign(mapStructureKeys(responseTemplate.dataStructure) as DataStructure, { id: CASUS_IDS.DATASTRUCTURE_ID })
    Object.assign(responseTemplate, { dataStructure: parsedDataStructure })
    // remove parsing when BE migrates (removes) externalAPI field duplicates
    const parsedExternalAPIs = Object.entries(responseTemplate.externalAPIs || {}).reduce(
      (result, [id, fields]) => Object.assign(result, { [id]: Array.from(new Set(fields)) }),
      {}
    )
    Object.assign(responseTemplate, { externalAPIs: parsedExternalAPIs })
    // remove parsing when BE migrates (removes) segments locations contentStyle duplicates
    Object.values(responseTemplate.locations?.segments || {})
      .flat(1)
      .forEach(marker => {
        if (!marker.contentStyles?.length) return
        const arrayOfUniques = Array.from(new Set(marker.contentStyles))
        if (arrayOfUniques.length !== marker.contentStyles.length) Object.assign(marker, { contentStyles: arrayOfUniques })
      })
    //@ts-ignore
    Object.assign(responseTemplate, { description: responseTemplate.characteristics }) && delete responseTemplate.characteristics
    //@ts-ignore
    Object.assign(responseTemplate, { approvals: responseTemplate.approvers }) && delete responseTemplate.approvers
    /////////////////////////////////////////////////////////////////////////// //
    /////////////////////////////////////////////////////////////////////////// //
    /////////////////////////////////////////////////////////////////////////// //
    return responseTemplate
  }

  public uploadPreviewImage = async (id?: string, src?: string, width?: number, height?: number) => {
    if (!(id && src && width && height)) return
    const tempImg = document.createElement('img')
    tempImg.src = src
    const canvas = document.createElement('canvas')
    // canvas.width = width
    // canvas.height = height
    canvas.width = width / 2
    canvas.height = height / 2
    const ctx = canvas.getContext('2d')
    let res = undefined as unknown as string
    const onLoadHandler = ({ target }: Event) => {
      if (!target) return
      // ctx!.drawImage(target as HTMLImageElement, 0, 0)
      ctx!.drawImage(target as HTMLImageElement, 0, 0, width / 2, height / 2)
      canvas.toBlob(async blob => {
        if (!blob) return
        res = await uploadBlobToFirebaseStorage(`/templates/${id}/preview.png`, blob)
      })
    }
    tempImg.addEventListener('load', onLoadHandler)
    document.body.appendChild(tempImg)
    // tempImg.setAttribute('style', 'position: absolute')
    document.body.removeChild(tempImg)
    return res
  }

  public updateTemplate = async (template: PartialTemplate, publicFlow: boolean = false) => {
    if (!template.id) return
    const retainKeys = ['name', 'dataStructure', 'locations', 'questions', 'questionLayout', 'languages', 'externalAPIs', 'splits', 'category']
    const payload = Object.assign({}, template)
    ;(Object.keys(payload) as (keyof ITemplate)[]).forEach(key => !retainKeys.includes(key) && delete payload[key])
    /////////////////////////////////////////////////////////////////////////// //
    ///////////////////////////// V3 ROLLOUT TODO ///////////////////////////// //
    /////////////////////////////////////////////////////////////////////////// //
    // remove unparsing when BE fixes styleName => customStyle & id(number) => id(string)
    if (template.dataStructure) Object.assign(payload, { dataStructure: unparseDataStructure(template.dataStructure) })
    if (template.description) Object.assign(payload, { characteristics: template.description }) && delete template.description
    if (template.approvals) Object.assign(payload, { approvers: template.approvals }) && delete template.approvals
    if (template.category) Object.assign(payload, { category: [template.category] })
    const res = await this.post(`${publicFlow ? 'public/' : ''}${template.id}`, payload)
    // const res = await this.patch(template.id, payload)
    const responseTemplate = res.data.data as ITemplate
    // remove parsing when BE fixes styleName => customStyle & id(number) => id(string)
    const parsedDataStructure = Object.assign(mapStructureKeys(responseTemplate.dataStructure) as DataStructure, { id: CASUS_IDS.DATASTRUCTURE_ID })
    Object.assign(responseTemplate, { dataStructure: parsedDataStructure })
    // remove parsing when BE migrates (removes) externalAPI field duplicates
    const parsedExternalAPIs = Object.entries(responseTemplate.externalAPIs || {}).reduce(
      (result, [id, fields]) => Object.assign(result, { [id]: Array.from(new Set(fields)) }),
      {}
    )
    Object.assign(responseTemplate, { externalAPIs: parsedExternalAPIs })
    // remove parsing when BE migrates (removes) segments locations contentStyle duplicates
    Object.values(responseTemplate.locations?.segments || {})
      .flat(1)
      .forEach(marker => {
        if (!marker.contentStyles?.length) return
        const arrayOfUniques = Array.from(new Set(marker.contentStyles))
        if (arrayOfUniques.length !== marker.contentStyles.length) Object.assign(marker, { contentStyles: arrayOfUniques })
      })
    //@ts-ignore
    Object.assign(responseTemplate, { description: responseTemplate.characteristics }) && delete responseTemplate.characteristics
    //@ts-ignore
    Object.assign(responseTemplate, { approvals: responseTemplate.approvers }) && delete responseTemplate.approvers
    /////////////////////////////////////////////////////////////////////////// //
    /////////////////////////////////////////////////////////////////////////// //
    /////////////////////////////////////////////////////////////////////////// //
    // const res = await this.patch(template.id, payload)
    // const responseTemplate = res.data.data as ITemplate
    const parsedCssData = parseCssData(responseTemplate.cssData as Record<string, string>)
    const parsedStyles = Object.assign(parsedCssData, { standardStyles: parseStyles(parsedCssData, responseTemplate.dataStructure) })
    Object.assign(responseTemplate, { styles: parsedStyles })
    return responseTemplate
  }

  public duplicateTemplate = async (id: string) => {
    const res = await this.post(`${id}/duplicate`)
    // await this.patch(`${id}/duplicate`)
    return res.data.data as ITemplate
  }

  public removeTemplate = async (id: string) => {
    await this.post(id, { status: 'trashed' })
    // await this.patch(id, { status: 'trashed' })
    return
  }

  public shareTemplate = async (id: string, email: string, permissions: { use: boolean; edit: boolean }) => {
    /////////////////////////////////////////////////////////////////////////// //
    ///////////////////////////// V3 ROLLOUT TODO ///////////////////////////// //
    /////////////////////////////////////////////////////////////////////////// //
    // remove when BE updates permissions
    const payload = { email, permissions: { read: permissions.use, write: permissions.edit } }
    const res = await this.post(`${id}/share`, payload, undefined, 'v1')
    /////////////////////////////////////////////////////////////////////////// //
    /////////////////////////////////////////////////////////////////////////// //
    /////////////////////////////////////////////////////////////////////////// //
    // const res = await this.post(`${id}/share`, payload)
    return res.data.data as ITemplate
  }

  public unshareTemplate = async (id: string, shareId: string) => {
    const res = await this.delete(`${id}/share/${shareId}`, undefined, 'v1')
    return res.data.data as ITemplate
  }

  public toggleCompanyShare = async (id: string) => {
    const res = await this.post(`${id}/company`)
    return res.data.data as IUser
  }

  public getTemplatePreviewImageSrcURL = async (id?: string) => (id ? getImageSrcURL(`/templates/${id}/preview.png`) : undefined)

  public getTemplateFolderList = async (id?: string | null): Promise<ITemplateFolder[] | undefined> => {
    if (!id) return
    let res = undefined as any
    if (Object.values(TEMPLATES_FILTERS).includes(id as TemplatesFilter))
      res = await this.get(`list/${mapTemplatesFilter(id)}`, undefined, 'v1', 'templateCategories')
    else res = await this.post(`list/${mapTemplatesFilter(TEMPLATES_FILTERS.MINE)}/category`, { category: id }, undefined, 'v1', 'templateCategories') // update path to templates/folders/list (remove the override)
    const folders = res.data.data.folders as ITemplateFolder[]
    /////////////////////////////////////////////////////////////////////////// //
    ///////////////////////////// V3 ROLLOUT TODO ///////////////////////////// //
    /////////////////////////////////////////////////////////////////////////// //
    // remove parsing when BE updates categories to folders
    folders.forEach(folder => {
      //@ts-ignore
      Object.assign(folder, { parentFolderId: folder.parentCategoryId })
      //@ts-ignore
      delete folder.parentCategoryId
    })
    return folders
  }

  public createTemplateFolder = async (name: string, parentFolder?: string | null) => {
    const payload = { name, parentCategoryId: '' }
    if (parentFolder && !Object.values(TEMPLATES_FILTERS).includes(parentFolder as TemplatesFilter))
      Object.assign(payload, { parentCategoryId: parentFolder })
    const res = await this.post('', payload, undefined, 'v1', 'templateCategories')
    return res.data.data as ITemplateFolder
  }
}

export const templatesAPI = new Templates()

export default templatesAPI
