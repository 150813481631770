import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  QUERY_KEYS,
  useFetchEmployeeCategories,
  // useUploadToEmployee
} from '___queries'
import { Button, Select, Switch } from 'components/CasusComponents'
import { wizardLayoutLeftPaneDocumentPreviewUploadToBambooClasses as classes } from 'Layouts/WizardLayout'
import { useTranslation } from 'react-i18next'
import { Download } from 'assets/svgIconComponents'
import { documentsAPI } from '___api'
import { IDocument, ITemplate } from '___types'
import { parseAnswersForDownload, parseHeadersFooters } from '___queries/documents'
import { useQueryClient, UseQueryResult } from 'react-query'

interface UploadToBambooProps {
  documentId: string
  templateId: string
  employeeId: string
  serviceType: string
  split?: string | null
}

const UploadToBamboo = ({ documentId, templateId, employeeId, serviceType, split }: UploadToBambooProps) => {
  const { data: categoriesRaw, isLoading: isDataLoading, isError, refetch } = useFetchEmployeeCategories({ employeeId, serviceType })
  // const upload = useUploadToEmployee()

  const [success, setSuccess] = useState<boolean | null>(null)
  const [error, setError] = useState<boolean | null>(null)
  const [uploading, setUploading] = useState(false)
  const upload = useCallback(
    (
      document: IDocument,
      template: ITemplate,
      categoryId: string,
      isShared: boolean,
      employeeId: string,
      serviceType: string,
      split?: string | null
    ) => {
      if (!(document && template)) return
      const { id, answers, languages, externalAPIs } = document
      const { dataStructure, locations } = template
      const payload = {
        storageId: dataStructure.storageId,
        paragraphs: parseAnswersForDownload(template!, languages, externalAPIs, split, answers),
        categoryId,
        employeeId,
        isShared,
        serviceType,
      }
      const allLocationParents = Object.keys(Object.assign({}, locations.segments, locations.text))
      const changedHeaders = parseHeadersFooters('headers', dataStructure, allLocationParents)
      const changedFooters = parseHeadersFooters('footers', dataStructure, allLocationParents)
      if (changedHeaders?.length) Object.assign(payload, { headers: changedHeaders })
      if (changedFooters?.length) Object.assign(payload, { footers: changedFooters })

      setUploading(true)
      setSuccess(null)
      setError(null)
      documentsAPI
        .uploadToBamboo(id, payload)
        .then(() => setSuccess(true))
        .catch(() => setError(true))
        .finally(() => setUploading(false))
    },
    []
  )

  const { t: translate } = useTranslation('translation', { keyPrefix: 'wizard.document-flow.document-preview.bamboo-upload' })

  const [selectedCategory, setSelectedCategory] = useState<string | undefined>()
  const [isShared, setIsShared] = useState(false)

  const isLoading = isDataLoading || uploading

  const queryClient = useQueryClient()

  const document = (queryClient.getQueryData([QUERY_KEYS.DOCUMENT, documentId]) as UseQueryResult<IDocument> | undefined)?.data as IDocument
  const template = (queryClient.getQueryData([QUERY_KEYS.DOCUMENT, templateId]) as UseQueryResult<ITemplate> | undefined)?.data as ITemplate

  const onUpload = () => {
    if (!selectedCategory) return
    upload(document, template, selectedCategory, isShared, employeeId, serviceType, split)
  }

  const categories = useMemo(
    () => (categoriesRaw && categoriesRaw?.reduce((result: any, entry: any) => Object.assign(result, { [entry.id]: `${entry.name}` }), {})) || {}, // Make Label dynamic to work with different integrations / accounts
    [categoriesRaw]
  )

  useEffect(() => {
    refetch()
  }, [employeeId, serviceType, refetch])

  const content = () => {
    if (isLoading) return <div>Loading...</div>
    if (isError) return <div>Error...</div>
    if (success) return <div>{translate('document-uploaded-with-success')}</div>
    if (error) return <div>Error uploading document</div>
    if (!categories) return <div>No categories found</div>
    if (categories.length === 0) return <div>No categories found</div>
    return (
      <div className={classes.select.wrapper}>
        <Select
          className={classes.select.dropdown}
          multiselect={false}
          options={categories}
          value={selectedCategory}
          placeholder={translate(`select-category`)}
          onSelect={setSelectedCategory}
        />
        <Switch className={classes.select.switch} value={isShared} onClick={toggle => setIsShared(toggle)} emphasized disabled={isLoading} />
        <span>{translate('share-with-employee')}</span>
        <Button secondary className={classes.select.button} onClick={onUpload}>
          <Download />
        </Button>
      </div>
    )
  }

  return (
    <div className={classes.wrapper}>
      <span className={classes.title}>{translate('upload-to-bamboo')}</span>
      {content()}
    </div>
  )
}

export default UploadToBamboo
