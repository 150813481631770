import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { styled } from '@material-ui/core'

import { useRootFolder } from 'hooks/useRootFolder'
import { useRootCategoryFolder } from 'hooks/useRootCategoryFolder'
import { useUser } from 'queries/user/useUser'
import { useLoadTemplates } from 'queries/templates'
import { useFetchFolders } from 'queries/folders'
import { useSubscriptionCheck } from 'hooks/useSubscriptionCheck'
import { useModal } from 'hooks/useModal'

import FolderList from 'components/files/Folder/FolderList.component'
import TemplatesList from './components/TemplatesList'
import FolderStructure from 'components/Sidebar/FolderStructure/FolderStructure'
import TemplatePreview from './components/TemplatePreview'
import Layout from './layouts/InnerLayout'
import { DocumentGenerationLayout } from 'components/features/documentGeneration/layout/DocumentGenerationLayout'
import { documentsRoutes } from 'constants/routes'
import { FolderStructureAdapter } from 'components/Sidebar/FolderStructure/services/FolderStructureAdapter'
import { TemplateType } from 'types'
import { UI_UPGRADE_SUBSCRIPTION_PLAN_MODAL_NOT_PAID } from 'constants/ui-elements'
import { USER_TYPE_CLIENT_EMPLOYEE } from 'constants/user-types'
import { DOCUMENT_DIRECTORY } from '___types'

const Wrapper = styled('div')({
  display: 'flex',
  flex: 1,
  flexShrink: 0,
})

const SidebarWrapper = styled('div')(({ theme }) => ({
  width: 336,
  position: 'relative',
  padding: theme.spacing(6, 4, 4, 4),
}))

const SelectTemplate: React.FC = () => {
  const { rootFolder } = useRootFolder('templates')
  const casusFolder = useRootCategoryFolder('templates', 'casus')
  const myFolder = useRootCategoryFolder('templates', 'my')
  const sharedFolder = useRootCategoryFolder('templates', 'shared')

  const user = useUser()

  const [currentFolder, setCurrentFolder] = useState(user?.type === USER_TYPE_CLIENT_EMPLOYEE ? sharedFolder : myFolder)
  const { push, location } = useHistory()
  const { openModal } = useModal()
  const { data, isFetchingNextPage, hasNextPage, fetchNextPage } = useLoadTemplates(currentFolder)

  const params = new URLSearchParams(location.search)
  const templateId = params.get('template')

  const templates = data?.pages.flat()
  const { data: folders } = useFetchFolders('templates', currentFolder)
  const isPublic = location.pathname.startsWith('/public')
  const { isExpired } = useSubscriptionCheck()

  const excludeFolders: TemplateType[] = isPublic ? ['my', 'trashed', 'shared'] : ['trashed']

  const theRootFolder = FolderStructureAdapter.adaptRootChildrenToUserSubscriptionType(
    rootFolder,
    user?.subscription.type,
    'templates',
    user?.casusTemplatesEnabled,
    excludeFolders
  )

  const onClose = () => {
    push(documentsRoutes.main)
  }

  useEffect(() => {
    if (isExpired) openModal(UI_UPGRADE_SUBSCRIPTION_PLAN_MODAL_NOT_PAID)
  }, [openModal, isExpired])

  useEffect(() => {
    if (user?.subscription.type === 'free' && user?.casusTemplatesEnabled && currentFolder.type !== 'casus') setCurrentFolder(casusFolder)
    else if (!user?.casusTemplatesEnabled && currentFolder.type === 'casus') setCurrentFolder(myFolder)
  }, [user, casusFolder, myFolder, currentFolder, setCurrentFolder])

  return (
    <DocumentGenerationLayout heading={isExpired ? 'Ihr Abo ist abgelaufen' : undefined} step={1} onClose={onClose}>
      <Wrapper>
        <SidebarWrapper>
          <FolderStructure
            rootFolder={theRootFolder}
            currentFolder={currentFolder}
            handleFolderClick={folder => setCurrentFolder(folder)}
            type="dark"
          />
        </SidebarWrapper>
        <Layout isLoading={!data || !folders}>
          <FolderList folders={currentFolder.children} handleClickFolder={folder => setCurrentFolder(folder)} />
          <TemplatesList
            templates={templates ?? []}
            onTemplateClick={templateId => push(`${DOCUMENT_DIRECTORY}/${templateId}/new?step=configure`)}
            currentFolder={currentFolder}
            fetchMore={fetchNextPage}
            isFetchingMore={isFetchingNextPage}
            hasMoreTemplates={hasNextPage}
          />
          {templateId && <TemplatePreview templateId={templateId} />}
        </Layout>
      </Wrapper>
    </DocumentGenerationLayout>
  )
}

export default SelectTemplate
