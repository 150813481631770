// import { DateTime } from 'luxon'
// import { v4 as uuid } from 'uuid'

import {
  SEGMENT_TYPES,
  SEGMENT_TAGS,
  // CASUS_KEYSTRINGS,
  CASUS_CLASSES,
  PAPER_NAMES,
  PXPI,
  PAGE_SIZES,
  ORIENTATION,
  NESTED_STRUCTURE_KEYS,
  IGNORE_STYLES,
  CASUS_KEYSTRINGS,
  CASUS_IDS,
  // CASUS_REGEXES,
  // CASUS_STYLE_CLASSES,
  // CASUS_STYLE_DEFAULTS,
} from './constants'
import // extractSubQuestions,
// parseQuestionLocations,
// findSegmentById,
// measureStructureSections
'./parsing'
import { mergeChunks } from './parsing'
import { generateParagraph, generateTextChunk } from '___store/storeSegments/wizard/helpers/editor-content'
import { CUSTOM_TEXT_SPLIT, extractPropertiesFromCustomText } from '___types'
import { getFormattedAnswerValue } from '___store/storeSegments/wizard/typified/helpers'

// import { classes as wizardClasses } from './Wizard'
// import { classes as editorClasses } from './components/Editor/Editor'
// import { classes as sectionClasses } from './components/Editor/components/Section'
// import { classes as pageClasses } from './components/Editor/components/Page'
// import { classes as choiceMarkerClasses } from './components/Editor/components/ChoiceMarker'

const layoutKeys = ['paper', 'orientation', 'top', 'bottom', 'left', 'right']
const defaultSectionLayout = { paper: PAPER_NAMES.A4, orientation: ORIENTATION.vertical, top: 1.25, left: 1, bottom: 0.75, right: 1 }
const applyRule = (margins, object, position) => Object.assign(object, { [`padding-${position}`]: `${margins[position] || 0}px` })
const applyRules = (margins, positionArray, startingRules) => positionArray.reduce((acc, cur) => applyRule(margins, acc, cur), startingRules || {})
const rulesToString = (identifier, rules) =>
  [identifier, '{', ...Object.entries(rules).map(([attribute, value]) => `${attribute}: ${value};`), '}'].join(' ')

const generatePageLayoutString = sectionLayouts =>
  sectionLayouts
    .split('; ')
    .reduce((acc, layoutString) => {
      const layout = (layoutString.match(/([^-])+/g) || []).reduce(
        (acc, value, i) => (value ? Object.assign(acc, { [layoutKeys[i]]: value }) : acc),
        Object.assign({}, defaultSectionLayout)
      )
      const { paper, orientation, top, left, bottom, right } = layout
      const { width: shortSide, height: longSide } = PAGE_SIZES[paper]
      const [width, minHeight] = orientation === 'portrait' ? [shortSide, longSide] : [longSide, shortSide]
      const margins = Object.entries({ top, left, bottom, right }).reduce((acc, [key, value]) => Object.assign(acc, { [key]: value * PXPI }), {})
      const layoutClass = layoutString.replaceAll('.', '_')
      const pageIdentifier = `.${layoutClass} > .${CASUS_CLASSES.pageContentRoot}`
      const segmentIdentifier = `.${layoutClass} > .${CASUS_CLASSES.pageContentRoot} > .${CASUS_CLASSES.segmentDiv}`
      const markerIdentifier = `.${layoutClass} > .${CASUS_CLASSES.pageContentRoot} > .${CASUS_CLASSES.segmentsMarkerDiv}`
      const segmentsMarkerContentIdentifier = `.${layoutClass} > .${CASUS_CLASSES.pageContentRoot} .${CASUS_CLASSES.segmentsMarkerDiv} > .${CASUS_CLASSES.segmentsMarkerContent}`
      // const segmentMarkerRules = applyRules(margins, ['left', 'right'])
      const segmentMarkerRules = { '--page-margin-left': `${margins.left}px`, '--page-margin-right': `${margins.right}px` }
      // const pageStyle = rulesToString(pageIdentifier, applyRules(margins, ['top', 'bottom'], { width: `${width}px` }))
      const pageStyle = rulesToString(pageIdentifier, applyRules(margins, ['top', 'bottom'], { width: `${width}px`, 'min-height': `${minHeight}px` }))
      const segmentStyle = rulesToString(segmentIdentifier, segmentMarkerRules)
      const markerStyle = rulesToString(markerIdentifier, segmentMarkerRules)
      const noStartStyle = `${segmentsMarkerContentIdentifier}[data-start="false"] { margin-top: calc(-${margins.top}px); padding-top: ${margins.top}px }`
      const noEndStyle = `${segmentsMarkerContentIdentifier}[data-end="false"] { margin-bottom: calc(-${margins.bottom}px); padding-bottom: ${margins.bottom}px }`
      return acc.concat([pageStyle, segmentStyle, markerStyle, noStartStyle, noEndStyle].join('\n'))
    }, [])
    .join('\n')

const extractStyleString = (styleObject = {}, prefix = '') =>
  Object.entries(styleObject).reduce((acc, [identifier, rules]) => {
    const [shallow, deep] = Object.entries(rules).reduce(
      (accumulated, [prop, value]) => {
        if (typeof value === 'string') accumulated[0] = Object.assign(accumulated[0], { [prop]: value })
        else accumulated[1] = Object.assign(accumulated[1], { [prop]: value })
        return accumulated
      },
      [{}, {}]
    )
    const shallowValues = Object.entries(shallow)
      .reduce((accumulated, [prop, value]) => [...accumulated, `${prop}: ${value};`], [])
      .join(' ')
    const shallowStyle = `${prefix}${identifier} { ${shallowValues} }`
    const deepStyles = extractStyleString(deep, `${prefix}${identifier}`)
    if (Object.values(shallow).length) acc.push(shallowStyle)
    if (deepStyles.length) acc.push(...deepStyles)
    return acc
  }, [])

const generateStyleString = (styleObject = {}) => {
  const { customStyles = {}, dynamicStyles = {}, customProperties = {} } = styleObject || {}
  const combined = [...extractStyleString(customStyles, '.'), ...extractStyleString(dynamicStyles, '.')]

  return [
    ...combined.map(
      s =>
        // `.${wizardClasses.wrapper} .${wizardClasses.content} .${editorClasses.wrapper} .${sectionClasses.section} .${pageClasses.page} .${pageClasses.content} .${CASUS_CLASSES.segmentDiv} ${s}`
        // `.${wizardClasses.wrapper} .${wizardClasses.content} .${editorClasses.wrapper} .${CASUS_CLASSES.section} .${CASUS_CLASSES.pageContentRoot} .${CASUS_CLASSES.segmentDiv} ${s}`
        // `.${CASUS_CLASSES.section} .${CASUS_CLASSES.pageContentRoot} .${CASUS_CLASSES.segmentDiv} ${s}`
        `.${CASUS_CLASSES.pageContentRoot} ${s}`
    ),
    ...extractStyleString(customProperties),
  ].join('\n')
}

// const generateListStartString = (numberingSystem = {}) =>
//   `.${CASUS_CLASSES.section} { counter-reset: ${Object.entries(numberingSystem)
//     .map(([systemKey, levels]) =>
//       levels.map((_, i) => `${CASUS_KEYSTRINGS.numberingSystemKey}_${systemKey}_${CASUS_KEYSTRINGS.numberingLevel}_${i + 1} 0`).join(' ')
//     )
//     .join(' ')} }`

// const generateListStylesString = (numberingSystem = {}) =>
//   Object.entries(numberingSystem)
//     .reduce((acc, [systemKey, systemObj]) => {
//       const listCounterStyles = systemObj.reduce((accumulated, styleConfig, i, entries) => {
//         const { styleName } = styleConfig
//         const resetString = `.${CASUS_CLASSES.segmentDiv}.${styleName} { counter-reset: ${CASUS_CLASSES.listDepthLevel}_${i + 2}_${systemKey} 0; }`
//         const content = styleConfig.combined
//           ? `${entries.reduce((contentString, entry, j) => {
//               return j < i
//                 ? `${contentString}'${entry.prefix}'counter(${CASUS_CLASSES.listDepthLevel}_${j + 1}_${systemKey}, ${entry.type})'${entry.suffix}${
//                     entry.combineString
//                   }'`
//                 : contentString
//             }, '')}'${styleConfig.prefix}'counter(${CASUS_CLASSES.listDepthLevel}_${i + 1}_${systemKey}, ${styleConfig.type})'${styleConfig.suffix}${
//               styleConfig.combineString
//             } '`
//           : `'${styleConfig.prefix}'counter(${CASUS_CLASSES.listDepthLevel}_${i + 1}_${systemKey}, ${styleConfig.type})'${styleConfig.suffix} '`
//         const tag = `${SEGMENT_TAGS[SEGMENT_TYPES.paragraph]}.${styleName}::before`
//         const textareaTag = `${SEGMENT_TAGS[SEGMENT_TYPES.textarea]}.${styleName}::before`
//         const style = `counter-increment: ${CASUS_CLASSES.listDepthLevel}_${i + 1}_${systemKey}; content: ${content};`
//         const listString = `${tag} { ${style} }`
//         const textareaListString = `${textareaTag} { ${style} }`
//         accumulated.push(resetString)
//         accumulated.push(listString)
//         accumulated.push(textareaListString)
//         return accumulated
//       }, [])
//       acc.push(...listCounterStyles)
//       return acc
//     }, [])
//     .join('\n')

const generateListStylesString = (numberingSystem = {}) =>
  Object.entries(numberingSystem)
    .reduce((acc, [systemKey, systemObj]) => {
      const listCounterStyles = systemObj.reduce((accumulated, styleConfig, i, entries) => {
        const { styleName, prefix, type, suffix, combined, combineString } = styleConfig
        const leading = combined
          ? entries.reduce((contentString, { prefix, type, suffix, combineString }, j) => {
              if (j >= i) return contentString
              const counter = `counter(${CASUS_KEYSTRINGS.numberingSystemKey}_${systemKey}${CASUS_KEYSTRINGS.numberingLevel}_${j}, ${type})`
              return `${contentString}'${prefix}'${counter}'${suffix}${combineString}'`
            }, '')
          : ''
        const counter = `counter(${CASUS_KEYSTRINGS.numberingSystemKey}_${systemKey}${CASUS_KEYSTRINGS.numberingLevel}_${i}, ${type})`
        const numbering = `'${prefix}'${counter}'${suffix}`
        const trailing = combined ? `${combineString} '` : " '"
        const content = `${leading}${numbering}${trailing}`
        const cssClass = styleName || `${CASUS_KEYSTRINGS.numbering}-${systemKey}-${i}`
        const tag = `${SEGMENT_TAGS[SEGMENT_TYPES.paragraph]}.${cssClass}::before`
        // const textareaTag = `${SEGMENT_TAGS[SEGMENT_TYPES.textarea]}.${styleName}::before`
        const style = `content: ${content};`
        const listString = `${tag} { ${style} }`
        // const textareaListString = `${textareaTag} { ${style} }`
        return accumulated.concat(listString)
      }, [])
      return acc.concat(listCounterStyles)
    }, [])
    .join('\n')
const generatePageListStartString = (numberingSystem = {}) => undefined

// const generatePlaceholder = (segments = []) => {
//   const firstSegment = segments[0]
//   const { styleName = '', styles = [], generate = 'textChunks', value = '' } = firstSegment
//   const placeholder = { start: +Infinity, styleName, styles }

//   switch (generate) {
//     case 'textChunks':
//       placeholder.text = ''
//       if (value) placeholder.value = value
//       break
//     case 'tableHeader':
//     case 'tableBody':
//     case 'tableFooter':
//       placeholder.type = SEGMENT_TYPES.tableRow
//       placeholder.tag = SEGMENT_TAGS[SEGMENT_TYPES.tableRow]
//       break
//     case 'cells':
//       placeholder.type = SEGMENT_TYPES.tableData
//       placeholder.tag = SEGMENT_TAGS[SEGMENT_TYPES.tableData]
//       break
//     case 'segments':
//     case 'content':
//     default:
//       placeholder.id = uuid()
//       placeholder.type = SEGMENT_TYPES.paragraph
//       placeholder.tag = SEGMENT_TAGS[SEGMENT_TYPES.paragraph]
//       placeholder.textChunks = [{ value }]
//   }
//   return placeholder
// }

// const unwrapSegments = (segments = [], highlight = false) =>
//   segments.map(segment =>
//     !Array.isArray(segment)
//       ? segment
//       : segment.reduce(
//           (acc, cur) => ({ ...acc, start: Math.min(acc.start, cur.start), length: (acc.length || 0) + cur.length }),
//           highlight ? { start: +Infinity, content: segment, tag: SEGMENT_TAGS[SEGMENT_TYPES.mark], id: highlight } : generatePlaceholder(segment)
//         )
//   )

// const changeSegments = (segments = [], change = {}, highlight = false, key = 'textChunks') =>
//   segments.reduce(
//     (acc, cur) => {
//       const [segmentsArray] = acc
//       if (acc[1]) segmentsArray.push(cur)
//       else {
//         const { start = 0, length = 0 } = cur
//         const end = start + length
//         const { start: changeStart = 0, length: changeLength = 0 } = change
//         const changeEnd = changeStart + changeLength
//         if (end <= changeStart) segmentsArray.push(cur)
//         else if (start >= changeEnd) acc[1] = segmentsArray.push(cur)
//         else {
//           const segmentWithin = start >= changeStart && end <= changeEnd
//           const isNesting = !!segmentsArray.length && Array.isArray(segmentsArray[segmentsArray.length - 1])
//           if (segmentWithin) {
//             const result = {
//               ...cur,
//               generate: key,
//             }
//             if (change.value !== CASUS_KEYSTRINGS.highlight) result.value = isNesting ? CASUS_KEYSTRINGS.remove : change.value
//             if (isNesting) segmentsArray[segmentsArray.length - 1].push(result)
//             else segmentsArray.push([result])
//           } else {
//             const result = applyChange(cur, change, highlight, isNesting && change.value !== CASUS_KEYSTRINGS.highlight && CASUS_KEYSTRINGS.remove)
//             if (isNesting) {
//               segmentsArray[segmentsArray.length - 1].push(result.shift())
//               if (result.length) acc[1] = segmentsArray.push(...result)
//             } else {
//               segmentsArray.push(result.shift())
//               if (result.length) segmentsArray.push(result)
//               if (result.length > 1) acc[1] = segmentsArray.push(result.pop())
//             }
//           }
//         }
//       }
//       return acc
//     },
//     [[], false]
//   )

// const changeTextChunks = (structure, change, propagatedValue) => {
//   const res = []
//   const { start = 0, length = 0 } = structure
//   const end = start + length
//   const { start: changeStart = 0, length: changeLength = 0 } = change
//   const changeEnd = changeStart + changeLength
//   const calculatedStart = Math.max(changeStart - start, 0)
//   const calculatedEnd = Math.max(Math.min(changeEnd - start, structure.text.length), 0)
//   const calculatedLength = calculatedEnd - calculatedStart
//   const leading = {
//     ...structure,
//     length: calculatedStart,
//     text: structure.text.slice(0, calculatedStart),
//   }
//   const base = {
//     ...structure,
//     start: Math.max(changeStart, start),
//     length: calculatedLength,
//     text: structure.text.slice(calculatedStart, calculatedEnd),
//   }
//   if (change.value !== CASUS_KEYSTRINGS.highlight) base.value = propagatedValue || change.value
//   const trailing = {
//     ...structure,
//     start: Math.min(changeEnd, end),
//     length: structure.text.length - calculatedEnd,
//     text: structure.text.slice(calculatedEnd),
//   }
//   if (leading.length && leading.length > 0) res.push(leading)
//   if (base.length && base.length > 0) res.push(base)
//   if (trailing.length && trailing.length > 0) res.push(trailing)
//   return res
// }

// const applyChange = (structure = {}, change = {}, highlight = false, propagatedValue) => {
//   const start = structure.id ? 0 : structure.start
//   const { length = 0 } = structure
//   const end = start + length
//   const { start: changeStart = 0, length: changeLength = 0 } = change
//   const changeEnd = changeStart + changeLength

//   if (end <= changeStart || start >= changeEnd) return [structure]
//   if (structure.text && structure.text.length) return changeTextChunks(structure, change, propagatedValue)

//   const result = { ...structure }
//   NESTED_STRUCTURE_KEYS.every(key => {
//     if (!(result[key] && result[key].length)) return true
//     const [replacedSegments, done] = changeSegments(result[key], change, highlight, key)
//     const res = unwrapSegments(replacedSegments, highlight && change.locationId)
//     result[key] = res
//     return !done
//   })
//   return [result]
// }

// const generateSegmentStructure = (section = {}, markers = [], highlight = false) => {
// const relevantMarkers = markers[section.id] || {}
// const relevantReplaceMarkers = relevantMarkers.replace || []
// const relevantRemoveMarkers = relevantMarkers.remove || []
// const relevantChanges = relevantReplaceMarkers.concat(relevantRemoveMarkers)
// const relevantHighlightMarkers = relevantMarkers.highlight || []

// const changed = relevantChanges.reduce((acc, cur) => applyChange(acc, cur)[0], section)
// const highlighted = !highlight ? changed : relevantHighlightMarkers.reduce((acc, cur) => applyChange(acc, cur, true)[0], changed)

// return NESTED_STRUCTURE_KEYS.reduce((acc, key) => {
//   if (highlighted[key] && highlighted[key].length)
//     // acc[key] = (highlighted[key] || []).map(object => generateChildren(object, replace, highlight))
//     acc[key] = (highlighted[key] || []).map(object => generateSegmentStructure(object, markers, highlight))
//   return acc
// }, section)
// }

// const generateDataStructure = (structure = {}, markers = [], highlight = false) => ({
//   ...structure,
//   sections: structure.sections?.map(section => generateSegmentStructure(section, markers[section.id], highlight)),
// })

// const splitMarkers = (locations = []) =>
//   locations.reduce(
//     (acc, location) => {
//       if (location.length) acc[[CASUS_KEYSTRINGS.remove, CASUS_KEYSTRINGS.highlight].indexOf(location.value) + 1].push(location)
//       return acc
//     },
//     [[], [], []]
//   )

// const isMarkerContained = (locations = [], marker = {}) => locations.some(location => location.start <= marker.start && location.end >= marker.end)

// const filterMarkers = (markerObject = {}, sections = []) =>
//   Object.entries(markerObject).reduce((a, [sectionId, segmentsObject]) => {
//     const section = sections.find(s => s.id === sectionId)
//     if (section) {
//       const segments = section.segments || []
//       a[sectionId] = Object.entries(segmentsObject).reduce((acc, [id, locations]) => {
//         const segment = findSegmentById(segments, id)
//         if (segment) {
//           const [replace, remove, highlight] = splitMarkers(locations)
//           const fullSegmentRemovalMarker = remove.find(marker => marker.start === 0 && marker.length === segment.length)
//           if (fullSegmentRemovalMarker) acc[id] = { remove: [fullSegmentRemovalMarker] }
//           else {
//             const removeFiltered = remove
//               .sort((a, b) => b.length - a.length)
//               .reduce((accumulated, current) => {
//                 if (!isMarkerContained(accumulated, current)) accumulated.push(current)
//                 return accumulated
//               }, [])
//             const replaceFiltered = replace
//               .sort((a, b) => b.length - a.length)
//               .reduce((accumulated, current) => {
//                 if (current.value !== CASUS_KEYSTRINGS.keep && !isMarkerContained([...removeFiltered, ...accumulated], current))
//                   accumulated.push(current)
//                 return accumulated
//               }, [])
//             const highlightFiltered = highlight.filter(marker => !isMarkerContained(removeFiltered, marker))
//             const resultingMarkers = { replace: replaceFiltered, remove: removeFiltered, highlight: highlightFiltered }
//             if (resultingMarkers.replace.length + resultingMarkers.remove.length + resultingMarkers.highlight.length) acc[id] = resultingMarkers
//           }
//         }
//         return acc
//       }, {})
//     }
//     return a
//   }, {})

// const generateMarkers = (questions = [], answers = []) =>
//   questions.reduce((acc, question) => {
//     const relativeAnswer = answers.find(({ questionId }) => questionId === question.id)
//     const locations = parseQuestionLocations(question)
//     const result = locations.reduce((accumulated, { segmentId, questionId, locationId, optionId, start, length }) => {
//       const questionType = question.type
//       let value = CASUS_KEYSTRINGS.highlight
//       if (relativeAnswer) {
//         if (optionId) value = relativeAnswer.value.includes(optionId) ? CASUS_KEYSTRINGS.keep : CASUS_KEYSTRINGS.remove
//         else value = relativeAnswer.value
//       }
//       const res = { segmentId, questionId, locationId, start, length, value, questionType }
//       const sectionId = 'sct1' // get from location when implemented
//       if (!accumulated[sectionId]) accumulated[sectionId] = { [segmentId]: [res] }
//       else if (!accumulated[sectionId][segmentId]) accumulated[sectionId][segmentId] = [res]
//       else accumulated[sectionId][segmentId].push(res)
//       return accumulated
//     }, acc)
//     return result
//   }, {})

// const parseStructure = (tempDataStructure = {}, nestedQuestions = [], answers = [], highlight = false) => {
//   const structure = JSON.parse(JSON.stringify(tempDataStructure))
//   const sections = measureStructureSections(structure)
//   structure.sections = sections
//   const questions = extractSubQuestions(nestedQuestions)
//   const markers = generateMarkers(questions, answers)
//   const filteredMarkers = filterMarkers(markers, sections)
//   const result = generateDataStructure(structure, filteredMarkers, highlight)
//   return result
// }

export {
  generatePageLayoutString,
  generateStyleString,
  // generateListStartString,
  generateListStylesString,
  generatePageListStartString,
  // parseStructure
}

const parseTextChunks = (markers = [], textChunks = []) =>
  markers.reduce(
    (result, marker) =>
      result.reduce(
        (iteratedChunks, cur) => {
          const { chunks } = iteratedChunks
          const { id, range } = marker
          const [markerStart, markerEnd] = range
          const { tag, text = '' } = cur
          if (tag === SEGMENT_TAGS[SEGMENT_TYPES.mark]) {
            iteratedChunks.length += (cur.textChunks || []).reduce((sum, { text }) => sum + text.length, 0)
            return chunks.push(cur) && iteratedChunks
          }
          const chunkStart = iteratedChunks.length
          const chunkEnd = chunkStart + text.length
          if (markerStart >= chunkEnd || markerEnd <= chunkStart) {
            iteratedChunks.length += text.length
            return chunks.push(cur) && iteratedChunks
          }
          const relativeStart = markerStart - chunkStart
          const relativeEnd = markerEnd - chunkStart
          const pre = text.slice(0, Math.max(relativeStart, 0))
          const inside = text.slice(Math.max(relativeStart, 0), Math.min(relativeEnd, text.length))
          const post = text.slice(Math.min(relativeEnd, text.length), text.length)
          const resultingChunks = []
          const lastChunk = chunks[chunks.length - 1]
          const generateNewChunk = text => Object.assign({}, cur, { text })
          if (lastChunk?.tag === SEGMENT_TAGS[SEGMENT_TYPES.mark] && lastChunk.id === id) lastChunk.textChunks.push(generateNewChunk(inside))
          else resultingChunks.push({ id, tag: SEGMENT_TAGS[SEGMENT_TYPES.mark], textChunks: [generateNewChunk(inside)] })
          if (pre.length) resultingChunks.unshift(generateNewChunk(pre))
          if (post.length) resultingChunks.push(generateNewChunk(post))
          chunks.push(...resultingChunks)
          iteratedChunks.length += text.length
          return iteratedChunks
        },
        { chunks: [], length: 0 }
      ).chunks,
    textChunks
  )

const createTextContent = chunks =>
  chunks.map((chunk, i) => {
    const { id, tag, text = '', customStyle = '', styles = [], textChunks } = chunk
    if (tag === SEGMENT_TAGS[SEGMENT_TYPES.mark]) return { type: 'marker', id, textChunks }
    return { type: 'text', id, text, classes: [CASUS_CLASSES.textChunk, customStyle, ...styles].filter(s => s && !IGNORE_STYLES.includes(s)) }
    // const { id, tag, text = '', customStyle = '', styles = [], textChunks } = chunk
    // if (tag === SEGMENT_TAGS[SEGMENT_TYPES.mark]) return <TextMarker key={id} id={id} textChunks={textChunks} />
    // const spanClasses = [CASUS_CLASSES.textChunk, customStyle, ...styles].filter(s => s && !IGNORE_STYLES.includes(s))
    // if (spanClasses.length < 2) return text
    // return (
    //   <span key={`segment-${id}-chunk-${i}`} className={spanClasses.join(' ')}>
    //     {text}
    //   </span>
    // )
  })

// const generateDefaultStyles = () =>
//   Object.values(CASUS_STYLE_CLASSES)
//     .reduce((acc, styleClass) => {
//       const classProps = Object.entries(CASUS_STYLE_DEFAULTS[styleClass]).map(([property, value]) => `${property}: ${value};`)
//       return acc.concat(`.${CASUS_CLASSES.segmentDiv} .${styleClass} { ${classProps.join(' ')} }`)
//     }, [])
//     .join('\n')

const extractStylesFromChunks = chunks =>
  Array.from(
    new Set(
      chunks
        .reduce((acc, { customStyle, styles }) => acc.concat(styles.reduce((acc, style) => acc.concat(style), [customStyle]).filter(s => s)), [])
        .map(s => s.replace(/^_casus_/, ''))
      // .map(s => (Object.entries(CASUS_STYLE_CLASSES).find(([k, v]) => v === s) || [])[0] || s.replace(/^_casus_/, ''))
    )
  )

export { parseTextChunks, createTextContent, extractStylesFromChunks }

const findIndices = (array = [], key = 'text', range = [], buffer = 0, startIndex = -1, endIndex = -1) =>
  array.find((entry, i) => {
    const start = buffer
    const end = buffer + entry[key].length
    if (range[0] >= start && range[0] <= end) startIndex = i
    return (buffer = end) && range[1] >= start && range[1] <= end && ((endIndex = i) || true)
  }) && [startIndex, endIndex]

const applyTextMarkers = (structure = {}, textMarkers = []) => {
  if (!textMarkers.length) return structure
  const { textChunks = [] } = structure
  const structureText = textChunks.map(({ text }) => text).join('')
  return Object.assign({}, structure, {
    textChunks: mergeChunks(
      textMarkers
        .reduce((acc, marker) => {
          if (marker.keep && !(marker.replace || marker.replace === '')) return acc
          const { range } = marker
          const [startIndex, endIndex] = findIndices(acc, 'text', range)
          const startBuffer = acc.slice(0, startIndex).reduce((acc, { text }) => acc + text.length, 0)
          const endBuffer = acc.slice(0, endIndex).reduce((acc, { text }) => acc + text.length, 0)
          const leading = Object.assign({}, acc[startIndex], { text: acc[startIndex].text.slice(0, range[0] - startBuffer) })
          const trailing = Object.assign({}, acc[endIndex], { text: acc[endIndex].text.slice(range[1] - endBuffer) })
          // ADD CHUNK STYLE COMBINATION //

          const formattedReplace = marker.replace
            ?.split(CUSTOM_TEXT_SPLIT)
            .map(replaceString => {
              const properties = extractPropertiesFromCustomText(replaceString, 'markerReplace')
              const replacementText = properties.type ? getFormattedAnswerValue(properties.type, properties.value) : replaceString
              return replacementText ?? null
            })
            .join(marker.concatString || ' - ')

          const insert = Object.assign({}, acc[startIndex], { text: structureText.slice(...range), replace: marker.keep ? formattedReplace : '' })
          const resultingChunks = acc.slice()
          resultingChunks.splice(startIndex, endIndex - startIndex + 1, leading, insert, trailing)
          return resultingChunks
        }, textChunks)
        .map(chunk => Object.assign({}, chunk, { text: chunk.replace ?? chunk.text }))
        .filter(({ text }) => text.length)
    ),
  })
}

const applySegmentsMarkers = (structure = {}, segmentsMarkers = []) => {
  if (!segmentsMarkers.length) return structure
  const relevantContentKey = Object.keys(structure).includes('segments') ? 'segments' : 'content'
  const structureContent = structure[relevantContentKey]
  return Object.assign({}, structure, {
    [relevantContentKey]: segmentsMarkers
      .reduce(
        (acc, marker) => {
          if (marker.keep && !(marker.replace || marker.replace === '')) return acc
          const { range } = marker
          const [startIndex, endIndex] = findIndices(acc, 'content', range)
          const startBuffer = acc.slice(0, startIndex).reduce((acc, { content }) => acc + content.length, 0)
          const endBuffer = acc.slice(0, endIndex).reduce((acc, { content }) => acc + content.length, 0)
          const leading = { content: acc[startIndex].content.slice(0, range[0] - startBuffer) }
          const trailing = { content: acc[endIndex].content.slice(range[1] - endBuffer) }
          const replaceStyles = structureContent.slice(...range).reduce(
            (acc, { customStyle, styles }) => ({
              styles: Array.from(new Set(acc.styles.concat(styles))),
              customStyle: Array.from(new Set(acc.customStyle.concat(customStyle))),
            }),
            { styles: [], customStyle: [] }
          )
          const insert = {
            content: structureContent.slice(...range),
            replace: marker.keep
              ? marker.replace?.split(CUSTOM_TEXT_SPLIT).map(replaceString => {
                  const properties = extractPropertiesFromCustomText(replaceString, 'markerReplace')
                  const replacementText = properties.type ? getFormattedAnswerValue(properties.type, properties.value) : replaceString
                  const replaceParagraph = { textChunks: [generateTextChunk({ text: replacementText })], styles: replaceStyles.styles }
                  if (replaceStyles.customStyle.length === 1) Object.assign(replaceParagraph, { customStyle: replaceStyles.customStyle[0] })
                  return generateParagraph(replaceParagraph)
                }) || []
              : [],
          }
          const resultingSegments = acc.slice()
          resultingSegments.splice(startIndex, endIndex - startIndex + 1, leading, insert, trailing)
          return resultingSegments
        },
        [{ content: structure[relevantContentKey] }]
      )
      .reduce((acc, { content, replace }) => acc.concat(replace || content), []),
  })
}

const getNestedMarkers = (segmentsLocations = {}, markerArray = []) =>
  markerArray.reduce(
    (acc, { id, keep, replace }) =>
      !keep || replace || replace === '' || !segmentsLocations[id] ? acc : acc.concat(getNestedMarkers(segmentsLocations, segmentsLocations[id])),
    markerArray.slice()
  )

const applyAnswerValuesToDataStructure = (structure = {}, locations = {}) => {
  const id = structure.id === CASUS_IDS.dataStructure ? 'root' : structure.id
  const textMarkers = getNestedMarkers(locations.text, locations.text[id])
  // const textMarkers = (id && locations.text[id]) || []
  const segmentsMarkers = getNestedMarkers(locations.segments, locations.segments[id])
  // const segmentsMarkers = (id && locations.segments[id]) || []
  return NESTED_STRUCTURE_KEYS.reduce(
    (acc, key) =>
      Array.isArray(acc[key]) && acc[key].length
        ? Object.assign(acc, { [key]: acc[key].map(obj => applyAnswerValuesToDataStructure(obj, locations)) }) && acc
        : acc,
    applyTextMarkers(applySegmentsMarkers(Object.assign({}, structure), segmentsMarkers), textMarkers)
  )
}

export { applyAnswerValuesToDataStructure }
