import React from 'react'
import Document from 'components/files/File/FileComponent'
import Container from 'components/files/components/Container/Container'
import { FolderItem, ITemplate } from 'types'
import LoadMoreButton from 'components/files/components/LoadMoreButton/LoadMoreButton'
import { useHistory } from 'react-router-dom'
import { DOCUMENT_DIRECTORY } from '___types'
import { useTranslation } from 'react-i18next'

interface Props {
  templates: ITemplate[]
  currentFolder: FolderItem
  isFetchingMore?: boolean
  hasMoreTemplates?: boolean
  fetchMore: () => void
  onTemplateClick: (templateId: string) => void
}

const TemplatesList: React.FC<Props> = ({ templates, isFetchingMore, hasMoreTemplates, fetchMore }) => {
  const { push } = useHistory()
  const { t } = useTranslation()

  return (
    <>
      <Container>
        {templates.map(templateItem => {
          return (
            <Document
              key={templateItem.id}
              id={templateItem.id}
              title={templateItem.name}
              edited={templateItem.edited}
              isCasus={templateItem.isCasus}
              isPremium={templateItem.isPremium}
              onClick={() => push(`${DOCUMENT_DIRECTORY}/${templateItem.id}/new?step=configure`)}
            />
          )
        })}
      </Container>
      {hasMoreTemplates && <LoadMoreButton text={t('load_more')} onClick={fetchMore} isLoading={!!isFetchingMore} />}
    </>
  )
}

export default TemplatesList
