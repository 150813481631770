import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Document from 'components/files/File/FileComponent'

import { Document as DocumentIcon } from 'assets/svgIconComponents'

import { EmptyTemplates, classes } from '.'
import { ITemplate } from 'types'
import { useTranslation } from 'react-i18next'
import { SkeletonFileComponent } from 'components/files/File'
import { useRecentTemplates } from 'queries/templates/useRecentTemplates'
import { Button } from 'components/CasusComponents'
import { useUser } from 'queries/user/useUser'
import { DOCUMENT_DIRECTORY } from '___types'

interface IProps {
  templates: ITemplate[] | undefined
}

const TemplatesList: React.FC<IProps> = ({ templates }) => {
  const {
    push,
    // location
  } = useHistory()

  const user = useUser()!!

  // const { t } = useTranslation()

  if (!templates) {
    return null
  }

  const selectTemplate = (templateId: string): void => {
    if (user?.version === 'v1' || user?.version === 'v2') {
      push(`/document/new/${templateId}`)
    } else if (user?.version === 'v3') {
      push(`/documents/${templateId}/configure`)
    }
  }

  return (
    <div className={classes.templatesList.wrapper}>
      {templates?.map(templateItem => {
        return (
          <Document
            key={templateItem.id}
            id={templateItem.id}
            title={templateItem.name}
            edited={templateItem.edited}
            isCasus={templateItem.isCasus}
            isPremium={templateItem.isPremium}
            onClick={() => selectTemplate(templateItem.id)}
          />
        )
      })}
    </div>
  )
}

const SkeletonList: React.FC = () => {
  return (
    <div className={classes.templatesList.wrapper}>
      <SkeletonFileComponent />
      <SkeletonFileComponent />
      <SkeletonFileComponent />
      <SkeletonFileComponent />
    </div>
  )
}

const NewDocumentPanel: React.FC = () => {
  const { t } = useTranslation()
  const { push } = useHistory()
  const user = useUser()!!

  const { data, refetch, isLoading } = useRecentTemplates({ enabled: true })

  useEffect(() => {
    refetch()
  }, [refetch])

  if (data && data.length === 0) {
    return <EmptyTemplates />
  }

  const goCreateDocument = (): void => {
    if (user?.version === 'v1' || user?.version === 'v2') {
      push('/document/new/')
    } else if (user?.version === 'v3') {
      push(`/${DOCUMENT_DIRECTORY}`)
    }
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.blurBg} />
      <div className={classes.title}>
        <Button onClick={goCreateDocument}>
          <DocumentIcon />
          {t('create_document')}
        </Button>
      </div>

      <div className={classes.filter.wrapper}>
        <div className={classes.filter.text}>{t('recent_templates')}</div>
        <div onClick={() => goCreateDocument()} className={classes.filter.text}>
          {t('show_all')} &gt;
        </div>
      </div>
      {!isLoading ? <TemplatesList templates={data} /> : <SkeletonList />}
    </div>
  )
}

export default NewDocumentPanel
